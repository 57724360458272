import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  .header {
    > .title {
      font-size: 16px;
      font-family: "Rammetto One";
    }
    > .numbers {
      margin-bottom: 8px;
    }
  }
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;

  > .actions {
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 10px;
      margin-left: auto;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`

interface IProps {
  title: string
  count: number
  total: number
  filters: JSX.Element[]
  pagination: JSX.Element
  actions: JSX.Element[]
}

export default (props: IProps) => {
  return (
    <Wrapper>
      <div className="header">
        <div className="title">{props.title}</div>
        <div className="numbers">
          {props.count} / {props.total}
        </div>
        <div>{props.pagination}</div>
      </div>
      <div className="filters">{props.filters}</div>
      <div className="actions">{props.actions}</div>
    </Wrapper>
  )
}
