import React from "react"
import AvatarsFeed from "../components/AvatarsFeed"

const AvatarsFeedPage = () => {
  if (typeof window === "undefined") return null

  return <AvatarsFeed />
}

export default AvatarsFeedPage
