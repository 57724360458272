import styled from 'styled-components'
import { colors } from '../../theme'

export const Wrapper = styled.div`
  height: 100%;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 15px;
  height: 100%;
`

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  > :first-child {
    color: ${colors.darkPurple};
    font-size: 17px;
    font-weight: bold;
    padding-left: 10px;
  }
`

export const NumberInput = styled.input`
  font-size: 21px;
  height: 30px;
  width: 50px;
`
