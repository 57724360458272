import { useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"
import { useUserPreferences } from "../../context/UserPreferences"
import GET_AVATARS from "./getAvatars.graphql"
import GET_COUNT from "./getCount.graphql"
import PUBLISH_AVATAR from "./publishAvatar.graphql"
import PUBLISH_AVATARS from "./publishAvatars.graphql"
import REJECT_AVATAR from "./rejectAvatar.graphql"

export default shouldFetch => {
  const client = useApolloClient()
  const [avatars, setAvatars] = useState([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(true)
  const [forceNetwork, setForceNetwork] = useState(true)
  const { config, updateConfig, loading: loadingConfig } = useUserPreferences()
  const [filters, setFilters] = useState({})
  const [count, setCount] = useState(0)

  const pageLength = config.avatarsFeedPageLength || 50

  const toggleReports = () => {
    setFilters(filters => {
      if (filters.nbReports && filters.nbReports.from === 1) {
        return filters
      }
      return {
        ...filters,
        nbReports: {
          from: 1,
        },
      }
    })
  }

  const fetch = async (force = false) => {
    if (loadingConfig) return
    if (!pageLength || pageLength < 1) return
    if (!hasMore) return
    setLoading(true)
    const { data } = await client.query({
      query: GET_AVATARS,
      variables: {
        skip: page * pageLength,
        limit: pageLength,
        filters,
      },
      fetchPolicy: force || forceNetwork ? "network-only" : "cache-first",
    })
    if (data && data.tutoring && data.tutoring.avatars) {
      setAvatars([...data.tutoring.avatars])
      if (data.tutoring.avatars.length < pageLength) {
        setHasMore(false)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (avatars.length === 0) {
      fetch(true)
    }
    // eslint-disable-next-line
  }, [avatars, filters])

  useEffect(() => {
    setAvatars([])
    setPage(0)
    setHasMore(true)
  }, [filters])

  useEffect(() => {
    if (config.avatarsFeedPageLength >= 1) {
      fetch()
    }
    // eslint-disable-next-line
  }, [config, filters])

  useEffect(() => {
    if (!shouldFetch) return
    if (hasMore) {
      // fetch()
    } else {
      setLoading(false)
      setForceNetwork(false)
    }
  }, [shouldFetch, page, hasMore])

  useEffect(() => {
    setPage(0)
    setHasMore(true)
  }, [forceNetwork])

  const updatePageLength = val => {
    updateConfig({ avatarsFeedPageLength: val })
  }

  const fetchCount = async () => {
    const { data } = await client.query({
      query: GET_COUNT,
      fetchPolicy: "network-only",
    })
    setCount(data.tutoring.counts.avatars)
  }

  const refresh = () => {
    setHasMore(true)
    setAvatars([])
    fetchCount()
    fetch(true)
  }

  useEffect(() => {
    fetchCount()
  }, [])

  const removeAvatarFromList = _id => {
    setAvatars(avatars.filter(c => c._id !== _id))
  }

  const publishAvatar = async ({ _id, taskType }, featureAvatar) => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_AVATAR,
      variables: { drawingId: _id, taskType, featureAvatar },
    })
    if (!errors) {
      removeAvatarFromList(_id)
      setCount(c => c - 1)
    }
  }

  const rejectAvatar = async ({ _id, taskType }) => {
    const { errors } = await client.mutate({
      mutation: REJECT_AVATAR,
      variables: { drawingId: _id, taskType },
    })
    if (!errors) {
      removeAvatarFromList(_id)
      setCount(c => c - 1)
    }
  }

  const publishAll = async () => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_AVATARS,
      variables: {
        drawings: avatars.map(c => ({ _id: c._id, taskType: c.taskType })),
        featureAvatars: false,
      },
    })
    if (!errors) {
      setAvatars([])
      setCount(c => c - pageLength)
    }
  }

  return {
    avatars,
    filters,
    loading,
    publishAvatar,
    count,
    refresh,
    publishAll,
    rejectAvatar,
    pageLength,
    updatePageLength,
    toggleReports,
  }
}
