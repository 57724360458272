import { Button, Checkmark, Close, IconButton, Refresh } from "nzk-react-components"
import React, { useState } from "react"
import FeedControls from "../FeedControls"
import Drawing from "../UI/Drawing"
import Loader from "../UI/Loader"
import { Actions, Content, NumberInput, Wrapper } from "./index.styles"
import useAvatarsFeed from "./useFeed"

const AvatarsFeed = () => {
  const {
    avatars,
    loading,
    publishAll,
    publishAvatar,
    rejectAvatar,
    pageLength,
    updatePageLength,
    refresh,
    count,
  } = useAvatarsFeed(true)
  const [nbRequiredPress, setNbRequiredPress] = useState(3)

  if (loading) return <Loader />

  const onPublishAllPressed = () => {
    if (nbRequiredPress <= 1) {
      setNbRequiredPress(3)
      return publishAll()
    }
    setNbRequiredPress(nbRequiredPress - 1)
  }

  return (
    <Wrapper>
      <FeedControls
        title="Avatars Feed"
        count={avatars.length}
        total={count}
        filters={[]}
        pagination={
          <div>
            Pages of{" "}
            <NumberInput
              defaultValue={pageLength}
              onBlur={e => {
                updatePageLength(parseInt(e.target.value, 10) || 0)
              }}
            />{" "}
            avatars
          </div>
        }
        actions={[
          <IconButton
            key='1'
            theme="confirm"
            size="small"
            icon={<Checkmark />}
            onClick={onPublishAllPressed}
          >
            Publish all ({nbRequiredPress})
          </IconButton>,
          <IconButton
            key='2'
            theme="primary"
            size="x-small"
            icon={<Refresh />}
            onClick={refresh}
          >
            Refresh
          </IconButton>,
        ]}
      />
      <Content>
        {avatars.map(avatar => (
          <Drawing
            {...avatar}
            key={avatar._id}
            actions={[
              avatar.status !== "published" ? (
                <IconButton
                  key="publish"
                  theme="confirm"
                  size="x-small"
                  icon={<Checkmark />}
                  onClick={() => publishAvatar(avatar, false)}
                >
                  Publish
                </IconButton>
              ) : null,
              avatar.status !== "archived" ? (
                <IconButton
                  key="flag"
                  theme="orange"
                  size="x-small"
                  icon={<Close />}
                  onClick={() => rejectAvatar(avatar)}
                >
                  Reject
                </IconButton>
              ) : null,
            ].filter(d => d)}
            bottomActions={[
              avatar.status !== "archived" && (
                <Button
                  round
                  primary
                  key="reject-action"
                  theme="red"
                  size="small"
                  onClick={() => rejectAvatar(avatar)}
                >
                  <Close />
                </Button>
              ),
              avatar.status !== "published" && (
                <Button
                  round
                  theme="confirm"
                  size="small"
                  key="publish-avatar"
                  onClick={() => publishAvatar(avatar, false)}
                >
                  <Checkmark />
                </Button>
              ),
            ].filter(d => d)}
          >
            {avatar.text}
          </Drawing>
        ))}
      </Content>
      <Actions>
        <div>
          {avatars.length} / {count} avatars
        </div>
        <IconButton
          theme="confirm"
          icon={<Checkmark />}
          onClick={onPublishAllPressed}
          size="regular"
        >
          Publish all ({nbRequiredPress})
        </IconButton>
      </Actions>
    </Wrapper>
  )
}

AvatarsFeed.propTypes = {}

AvatarsFeed.defaultProps = {}

export default AvatarsFeed
